import { render, staticRenderFns } from "./CourseTabGeneralInformation.vue?vue&type=template&id=5a521b90&scoped=true"
import script from "./CourseTabGeneralInformation.vue?vue&type=script&lang=js"
export * from "./CourseTabGeneralInformation.vue?vue&type=script&lang=js"
import style0 from "./CourseTabGeneralInformation.vue?vue&type=style&index=0&id=5a521b90&prod&scoped=true&lang=css"
import style1 from "./CourseTabGeneralInformation.vue?vue&type=style&index=1&id=5a521b90&prod&lang=css"
import style2 from "./CourseTabGeneralInformation.vue?vue&type=style&index=2&id=5a521b90&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a521b90",
  null
  
)

export default component.exports