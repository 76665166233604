import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchExams(ctx, { id, queryParams }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/courses/${id}/exams`, { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchExam(ctx, { slug }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/exams/${slug}/edit`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        publicationExam(ctx, { id, payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .patch(`/exams/${id}/publication`, payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updateExam(ctx, { id, payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/exams/${id}/update`, payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}