/*======================================================
    Page: /courses/:slug/edit
======================================================*/
<template>
    <validation-observer ref="refForm">

        <b-form
            @submit.prevent="onSubmit"
            @reset.prevent="resetForm"
        >

            <b-row>
                <b-col md="12">
                    <b-card footer-tag="footer">

                        <b-row>

                            <!-- CODE -->
                            <b-col
                                md="12"
                            >
                                <b-form-group
                                    label="CÓDIGO"
                                    label-for="code"
                                >
                                    <b-form-input
                                        id="code"
                                        v-model="formData.code"
                                        class="font-weight-bold text-primary"
                                        disabled
                                        readonly
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- NAME -->
                            <b-col
                                cols="12"
                            >
                                <b-form-group
                                    label="NOMBRE DEL SERVICIO"
                                    label-for="name"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="nombre del servicio"
                                    >
                                        <b-form-input
                                            id="name"
                                            v-model="formData.name"
                                            placeholder="Ingrese nombre del servicio ..."
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- MINIMUM SCORE -->
                            <b-col
                                cols="12"
                                md="4"
                            >
                                <b-form-group
                                    label="NOTA MÍNIMA APROBATORIA"
                                    label-for="minimum_score"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required|positive|between:1,20"
                                        name="nota mínima aprobatoria"
                                    >
                                        <b-form-input
                                            id="minimum_score"
                                            type="number"
                                            placeholder="0"
                                            v-model="formData.minimum_score"
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- AMOUNT -->
                            <b-col
                                cols="12"
                                md="4"
                            >
                                <b-form-group
                                    label="MONTO EN SOLES"
                                    label-for="amount"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required|positive"
                                        name="monto"
                                    >
                                        <b-form-input
                                            id="amount"
                                            placeholder="0.00"
                                            type="number"
                                            step=".01"
                                            v-model="formData.amount"
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- MAX INSCRIPTIONS -->
                            <b-col
                                cols="12"
                                md="4"
                            >
                                <b-form-group
                                    label="CANTIDAD MÁXIMA DE INSCRIPCIONES"
                                    label-for="max_inscriptions"
                                >
                                    <b-form-input
                                        id="max_inscriptions"
                                        placeholder="0"
                                        type="number"
                                        v-model="formData.max_inscriptions"
                                        trim
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- COURSE TYPE -->
                            <b-col
                                cols="12"
                            >
                                <b-form-group
                                    label="TIPO DE SERVICIO"
                                    label-for="course_type"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="tipo de servicio"
                                    >
                                        <v-select
                                            id="course_type"
                                            placeholder="Seleccionar una opción"
                                            v-model="formData.course_type"
                                            :options="options.courseTypes"
                                            @input="onCourseTypeChange"
                                            :state="errors.length > 0 ? false : null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- COURSE TYPE: SINCRÓNICO -->
                            <template v-if="formData.course_type && formData.course_type.value === 1">

                                <!-- CLASS LINK -->
                                <b-col
                                    cols="12"
                                >
                                    <b-form-group
                                        label="LINK DE LA CLASE"
                                        label-for="class_link"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            rules="required|url"
                                            name="link de la clase"
                                        >
                                            <b-form-input
                                                id="class_link"
                                                v-model="formData.class_link"
                                                placeholder="Ingrese el link de la clase (Zoom, Google Meet, TEAMS, etc.) ..."
                                                :state="errors.length > 0 ? false : null"
                                                trim
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <!-- CLASS DATIME -->
                                <b-col
                                    cols="12"
                                >
                                    <b-form-group
                                        label="FECHA Y HORA DE LA CLASE"
                                        label-for="class_datetime"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            rules="required"
                                            name="fecha y hora de la clase"
                                        >
                                            <b-input-group>
                                                <flat-pickr
                                                    id="class_datetime"
                                                    v-model="formData.class_datetime"
                                                    class="form-control"
                                                    :config="config"
                                                    placeholder="YYYY-MM-DD HH:mm"
                                                    :state="errors.length > 0 ? false : null"
                                                />
                                                <b-input-group-append>
                                                    <b-button
                                                        variant="primary"
                                                        @click="clearDate('class_datetime')"
                                                    >
                                                        <feather-icon
                                                            icon="XIcon"
                                                            size="12"
                                                        />
                                                    </b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                            </template>

                            <!-- COURSE TYPE: ASINCRÓNICO -->
                            <template v-if="formData.course_type && formData.course_type.value === 2">

                                <b-col cols="12">
                                    <!-- CARGAR ARCHIVO -->
                                    <b-form-group
                                        label="VIDEO DE LA CLASE"
                                        label-for="class_video"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            rules=""
                                            name="Video de la clase"
                                        >
                                            <b-form-file
                                                id="class_video"
                                                v-model="videoFile"
                                                accept="video/*"
                                                :state="Boolean(videoFile)? false : null"
                                                placeholder="Agregar el video del curso..."
                                                drop-placeholder="Arrastra tu video aquí..."
                                            ></b-form-file>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-container fluid class="" v-if="Boolean(videoFile)">
                                    <b-row align-h="center" align-v="center">
                                        <b-col cols="12" md="6" >
                                            <b-card>
                                                <b-aspect aspect="16:9">
                                                    <video ref="videoDom" style="max-width: 100%; width: fit-content; max-height: 600px; margin: auto" controls></video>
                                                </b-aspect>
                                            </b-card>
                                        </b-col>
                                    </b-row>
                                </b-container>
                                <b-container fluid class="" v-if="!Boolean(videoFile) && formData.video_id">
                                    <b-alert :show="courseData.video.vimeoState === 'complete' && courseData.video.transcodeStatus === 'in_progress'" variant="info">
                                        <b-icon icon="exclamation-circle-fill" variant="info"></b-icon>
                                        El video se está convirtiendo, puede que no vea cambios hasta que termine.
                                    </b-alert>
                                    <b-alert :show="courseData.video.vimeoState === 'in_progress'" variant="danger">
                                        <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
                                        Se espera la subida de un video
                                    </b-alert>
                                    <b-alert :show="courseData.video.transcodeStatus === 'error'" variant="danger">
                                        <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
                                        Ocurrió un problema con el video
                                    </b-alert>
                                    <b-row align-h="center" align-v="center">
                                        <b-col cols="12" md="6" >
                                            <b-card>
                                                <b-overlay
                                                    :show="courseData.video.transcodeStatus === 'in_progress'"
                                                    rounded
                                                    opacity="0.6"
                                                    spinner-small
                                                    bg-color="#000"
                                                    spinner-variant="primary"
                                                >
                                                    <b-aspect aspect="16:9">
                                                        <iframe
                                                            :src="courseData.video.vimeoUrl"
                                                            frameborder="0"
                                                            style="max-width: 100%; width: 100%; height: 100%; max-height: 600px; margin: auto"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowfullscreen
                                                        ></iframe>

                                                    </b-aspect>
                                                </b-overlay>
                                            </b-card>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </template>

                            <!-- DESCRIPTION -->
                            <b-col cols="12">
                                <b-form-group
                                    label="DESCRIPCIÓN"
                                    label-for="description"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="descripción"
                                    >
                                        <quill-editor
                                            id="description"
                                            style="min-height: 120px;"
                                            v-model="formData.description"
                                            :options="snowOption"
                                            :state="(errors.length > 0 ? false:null)"
                                            trim
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                        </b-row>

                        <template #footer>

                            <!-- BUTTON SUBMIT -->
                            <b-button-loading
                                text="ACTUALIZAR"
                                type="submit"
                                :processing="processing">
                            </b-button-loading>

                        </template>

                    </b-card>
                </b-col>
            </b-row>

        </b-form>

        <b-modal
            ref="loaderModalDom"
            title="Enviando Información"
            :hide-footer="false"
            hide-header-close
            no-close-on-backdrop
            no-close-on-esc
            centered
            v-show="loaderInfo.isLoadingInfo"
        >
            <b-row class="d-block px-1">
                <b-alert variant="info" show>
                    <b-icon icon="exclamation-circle-fill" variant="info"></b-icon>
                    No refrescar la página
                </b-alert>
            </b-row>
            <div class="d-block text-center">
                <div class="mb-2"
                    v-for="(loader, index) in loaderInfo.steps"
                    :key="`title-${index}`"
                >
                    <p class="mb-0 text-left pl-1">
                        {{ defineLoaderLabel(loader) }}
                    </p>
                    <b-progress
                        :max="100"
                        height="2rem"
                        show-progress
                        :animated="loader.state !== videoLoaderState.FINISHED"
                        :key="`loader-${index}`"
                    >
                        <b-progress-bar :value="loader.progress">
                            <div class="text-overflow-ellipsis" style="width: 90%; height: 100%; line-height: 2rem; margin: auto">
                                <span>Avance: <strong>{{ loader.progress }} / {{ 100 }}</strong></span>
                            </div>
                        </b-progress-bar>
                    </b-progress>
                </div>
            </div>
            <template #modal-footer>
                <div class="w-100">
                    <p class="float-left"></p>
                    <b-button-loading
                        variant="primary"
                        size="sm"
                        class="float-right"
                        text="Cancelar"
                        type="button"
                        @process-action="onCancelLoader"
                        :processing="false"
                    > </b-button-loading>
                </div>
            </template>
        </b-modal>


    </validation-observer>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { ref, onUnmounted, onBeforeMount, watchEffect } from '@vue/composition-api';
    import BButtonLoading from '@core/components/b-button-loading/BButtonLoading.vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { useToast } from 'vue-toastification/composition';
    import courseStoreModule from '../../courseStoreModule';
    import { required, positive } from '@validations';
    import { Spanish } from 'flatpickr/dist/l10n/es';
    import flatPickr from 'vue-flatpickr-component';
    import { quillEditor } from 'vue-quill-editor';
    import vSelect from 'vue-select';
    import router from '@/router';
    import store from '@/store';
    import axios from '@axios';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            BButtonLoading,
            quillEditor,

            flatPickr,

            required,
            positive,

            vSelect
        },
		props: {
            courseData: {
                type: Object,
                required: true
            }
        },
		setup(props) {
            const videoLoaderState = {
                INIT: 'init',
                LOADING: 'loading',
                FINISHED: 'finished'
            }

			// USE TOAST
			const toast = useToast();

			// REFS
            const courseData = ref(props.courseData);
            const processing = ref(false);
            const refForm = ref(null);
            const options = ref({});
            const videoFile = ref(null)

            const config = ref({
                locale: Spanish,
                enableTime: true
            });

			const formData = ref({
                code: null,
				name: null,
                course_type: null,
                video_id: null,
                class_link: null,
                class_datetime: null,
				description: null,
				minimum_score: null,
                max_inscriptions: null,
				amount: null
			});

            const snowOption = ref({
                theme: 'snow',
                placeholder: 'Escriba aquí...'
            });

            const loaderInfo = ref({
                isLoadingInfo: false,
                isLoadingCancel: false,
                loadedData: null,
                cancelUploadVideo: new AbortController(),
                isCancelledVideo: false,
                steps: [
                    {
                        initLabel: '• Enviar video',
                        label: '• Enviando video...',
                        finishedLabel: '• Video enviado ✓',
                        state: videoLoaderState.INIT,
                        progress: 0,
                    },
                    {
                        initLabel: '• Cargar video',
                        label: '• Cargando video...',
                        finishedLabel: '• Video cargado ✓',
                        variant: 'info',
                        state: videoLoaderState.INIT,
                        progress: 0,
                    },
                ]
            })

            // REFS DOM
            const videoDom = ref(null)
            const loaderModalDom = ref(null)

			const COURSE_APP_STORE_MODULE_NAME = 'app-course';

            const courseType = {
                SYNCHRONOUS: 1,
                ASYNCHRONOUS: 2,
            }

			// REGISTER MODULE
			if (!store.hasModule(COURSE_APP_STORE_MODULE_NAME)) store.registerModule(COURSE_APP_STORE_MODULE_NAME, courseStoreModule);

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(COURSE_APP_STORE_MODULE_NAME)) store.unregisterModule(COURSE_APP_STORE_MODULE_NAME);
			});

            // CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
            onBeforeMount( async () => {
               await getSelectFilters();
               setData();
            });

            const defineLoaderLabel = (loaderStep) => {
                if (loaderStep.state === videoLoaderState.INIT) {
                    return loaderStep.initLabel
                } else if (loaderStep.state === videoLoaderState.LOADING) {
                    return loaderStep.label
                } else if (loaderStep.state === videoLoaderState.FINISHED) {
                    return loaderStep.finishedLabel
                }
                return '[...]'
            }

            const getSelectFilters = async () => {
                const { data } = await axios.get('/selects/course-types');

				options.value = data;
            };

            const setData = () => {

                const courseType = options.value.courseTypes.find(item => item.value === courseData.value.course_type_id);

                const class_datetime = courseData.value.class_datetime ? new Date(courseData.value.class_datetime) : null;

                formData.value = {
                    code: courseData.value.code,
                    name: courseData.value.name,
                    description: courseData.value.description,
                    minimum_score: courseData.value.minimum_score,
                    class_link: courseData.value.class_link,
                    class_datetime,
                    amount: courseData.value.amount,
                    max_inscriptions: courseData.value.max_inscriptions,
                    course_type: courseType,
                    video_id: courseData.value.video_id
                }

            };

            const onCourseTypeChange = (e) => {
                if (!e) {

                    formData.value.course_type = null;

                    formData.value.induction_video = null;
                    formData.value.class_link = null;
                    formData.value.class_datetime = null;

                    videoFile.value = null
                } else {

                    if (e.value === 1) { // SINCRÓNICO (aprendizaje que sucede al mismo tiempo para el instructor y para el personal)
                        formData.value.induction_video = null;
                        videoFile.value = null
                    } else { // ASINCRÓNICO (aprendizaje que NO sucede al mismo tiempo para el instructor y para el personal)
                        formData.value.class_link = null;
                        formData.value.class_datetime = null;
                    }

                }
            }

            const clearDate = (index) => {
                formData.value[index] = null;
            };

            const onVideoFileInputChange = (videoFileValue) => {
                let media = URL.createObjectURL(videoFileValue);
                let video = videoDom.value
                video.src = media;
                video.style.display = "block";
                video.load();
            }

            watchEffect(() => {
                if (videoFile.value && videoDom.value) {
                    onVideoFileInputChange(videoFile.value)
                }
            })

            const resetLoaderInfo = () => {
                loaderInfo.value.isLoadingInfo = false
                loaderInfo.value.loadedData = null
                loaderInfo.value.steps[0].progress = 0
                loaderInfo.value.steps[0].state = videoLoaderState.INIT
                loaderInfo.value.steps[1].progress = 0
                loaderInfo.value.steps[1].state = videoLoaderState.INIT
            }

			const onSubmit = async () => {

                const isValid = await refForm.value.validate();
                let successMessage

                if (!isValid)
                {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Complete los campos requeridos.',
                            icon: 'AlertTriangleIcon',
                            variant: 'warning'
                        }
                    });

                    return;
                }

                loaderInfo.value.cancelUploadVideo = new AbortController()
                loaderInfo.value.isCancelledVideo = false

                /*======================================================
                    Synchronous Course sending
                ======================================================*/

				processing.value = true;

				let { name, course_type, description, minimum_score, amount, class_link, class_datetime, max_inscriptions } = formData.value;

				const payload = {
					name,
                    course_type: course_type.value,
                    class_link,
                    class_datetime,
					description,
					minimum_score,
					amount,
                    max_inscriptions
				}

                const formDataPayload = new FormData();

                Object.keys(payload).forEach((key)=>{
                    formDataPayload.append(key, payload[key]);
                });

                if(course_type.value === courseType.ASYNCHRONOUS && videoFile.value?.size){
                    formDataPayload.append('file_size', videoFile.value?.size)
                    formDataPayload.append('file_name', videoFile.value?.name)
                }

                let videoId = await store.dispatch('app-course/updateCourse', { id: courseData.value.id, formDataPayload })
					.then( response => {
                        successMessage = response.data.message
                        loaderInfo.value.loadedData = {
                            ...payload,
                            videoId: response.data.video_id,
                            courseTypeId: response.data.course_type_id,
                        }
                        if(course_type.value === courseType.ASYNCHRONOUS && videoFile.value) return response.data.video_id

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success'
                            }
                        });

                        loaderModalDom.value.hide();
					})
					.catch( (err) => {
						const message = err.response.data.message ? err.response.data.message : 'Error al actualizar la información general del servicio.';

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: message,
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });
					})
					.finally( () =>  processing.value = false);

                console.log("Video ID: ", videoId);

                /*======================================================
                    Asynchronous video sending
                ======================================================*/

                if(!videoId || course_type.value === courseType.SYNCHRONOUS) return;

                const callForLoadingProgress = () => {
                    const MAX_ERRORS_ALLOWED = 3
                    const TIMEOUT_REFRESH_PROGRESS = 2000
                    let errorsCount = 0
                    return new Promise((resolve, reject) => {
                        const getVideoUploadStatus = async () => {
                            if(loaderInfo.value.isCancelledVideo) return reject(new Error('Cancelado callForLoadingProgress'));
                            try {
                                const { headers } = await store.dispatch('app-course/fetchVideoCourseProgress', { videoId,  signalController: loaderInfo.value.cancelUploadVideo })
                                const progress = headers['progress-percentage']
                                const chargeVideoStep = loaderInfo.value.steps[1]
                                chargeVideoStep.progress = Number(progress) || 0
                                if(chargeVideoStep.progress < 100){
                                    setTimeout(getVideoUploadStatus, TIMEOUT_REFRESH_PROGRESS)
                                }
                                if(chargeVideoStep.progress >= 100) {
                                    resolve(videoId)
                                }
                            } catch (error) {
                                console.error("En la carga del progreso del video: ", error);
                                errorsCount += 1
                                if(errorsCount <= MAX_ERRORS_ALLOWED){
                                    setTimeout(getVideoUploadStatus, TIMEOUT_REFRESH_PROGRESS)
                                }
                                if(errorsCount > MAX_ERRORS_ALLOWED){
                                    reject(error)
                                }
                            }
                        }
                        getVideoUploadStatus()
                    })
                }

                /**
                 * Updates the loading state and progress of the sending video step.
                 *
                 * @param {ProgressEvent} progressEvent - The progress event object.
                 * @return {void} This function does not return anything.
                 */
                 const onLoading = (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    const sendingVideoStep = loaderInfo.value.steps[0];
                    if(sendingVideoStep.state === videoLoaderState.INIT) {
                        sendingVideoStep.state = videoLoaderState.LOADING
                        loaderModalDom.value.show();
                    }
                    sendingVideoStep.progress = Math.floor((loaded / total) * 100);
                    if(sendingVideoStep.progress >= 100) {
                        sendingVideoStep.state = videoLoaderState.FINISHED
                        callForLoadingProgress()
                    }
                    //return;
                }

                loaderModalDom.value.show();
                const videoFormData = new FormData()
                videoFormData.append('video', videoFile.value)

                /** ========== Sending video =========== **/
                await store.dispatch('app-course/addVideoCourse', { videoFormData, videoId, onLoading, signalController: loaderInfo.value.cancelUploadVideo} )
                .then( response => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: successMessage   ,
                            icon: 'CheckIcon',
                            variant: 'success'
                        }
                    });

                    loaderModalDom.value.hide();
                    refForm.value.reset();

                    setTimeout(async () => {
                         // RECARGAR LA VISTA
                        router.go()
                    }, 2000);

                })
                .catch( (err) => {
                    console.log("error: ",{ err});
                    const message = err.response?.data.message ? err.response.data.message : 'Error al editar el curso.';

                    onCancelLoader()

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: message,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    });

                    processing.value = false;
                });
			};

            const onCancelLoader = async ( ) => {
                loaderInfo.value.isLoadingCancel = true
                loaderInfo.value.isCancelledVideo = true
                const videoId = loaderInfo.value.loadedData?.videoId
                if(!videoId){
                    loaderInfo.value.isLoadingCancel = false
                    resetLoaderInfo()
                    loaderModalDom.value.hide();
                    return;
                };
                loaderInfo.value.cancelUploadVideo?.abort()
                await store.dispatch('app-course/cancelVideoCourseProgress', { videoId })
                    .then( () => {
                        console.info("Video upload cancelado");
                    }).catch( (error) => {
                        console.error("Error al cancelar el video: ", error);
                    }).finally(()=>{
                        resetLoaderInfo()
                        loaderInfo.value.isLoadingCancel = false
                        loaderModalDom.value.hide();
                    })

            }

			return {
                videoLoaderState,
				// REFS
                videoFile,
                loaderInfo,
                processing,
                snowOption,
                formData,
                refForm,
                options,
                config,

                //REFS DOM
                videoDom,
                loaderModalDom,

                // METHODS
                onCourseTypeChange,
                clearDate,
                onSubmit,
                defineLoaderLabel,
                onCancelLoader,
			}
		}
    }

</script>

<style scoped>

    .font-weight-bold {
        font-weight: bold !important;
    }

</style>

<style>

    .ql-container, .ql-editor {
        min-height: inherit;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/quill.scss';
</style>
