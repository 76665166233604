import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { useToast } from 'vue-toastification/composition';

export default function useCourseExamsList(courseId) {

	// Use toast
	const toast = useToast();

	const course = ref(courseId);
	const refExamsListTable = ref(null);

	const styleColumns = {
		thClass: 'text-center',
		tdClass: 'text-center'
	}

	// Table Handlers
	let tableColumns = [
		{ key: 'title', sortable: true, label: 'TÍTULO', thClass: 'text-center' },
		{ key: 'time', sortable: true, label: 'DURACIÓN <br> (MINUTOS)', ...styleColumns },
		{ key: 'total_score', sortable: true, label: 'PUNTAJE <br> TOTAL', ...styleColumns },
		{ key: 'attempts', sortable: false, label: 'NÚMERO <br> INTENTOS', ...styleColumns },
		{ key: 'questions_count', sortable: true, label: 'CANTIDAD PREGUNTAS <br> TOTAL', ...styleColumns },
		{ key: 'qty_random_questions', sortable: true, label: 'CANTIDAD PREGUNTAS <br> ALETORIAS', ...styleColumns },
		{ key: 'is_public', sortable: true, label: 'ESTADO', ...styleColumns },
		{ key: 'createdAt', sortable: true, label: 'FECHA REGISTRO', ...styleColumns },
		{ key: 'actions', sortable: false, label: 'OPCIONES', ...styleColumns }
	];

	const perPage = ref(10);
	const totalExams = ref(0);
	const currentPage = ref(1);
	const perPageOptions = [10, 25, 50, 100];
	const searchQuery = ref('');
	const sortBy = ref('createdAt');
	const isSortDirDesc = ref(true);

	const dataMeta = computed(() => {
		const localItemsCount = refExamsListTable.value ? refExamsListTable.value.localItems.length : 0;
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalExams.value
		}
	});

	const refetchData = () => {
		refExamsListTable.value.refresh();
	};

	watch([currentPage, perPage, searchQuery], () => {
		refetchData();
	});

	const fetchCourseExams = (ctx, callback) => {
		store
			.dispatch('app-exam/fetchExams', {
				id: course.value,
                queryParams: {
                    q: searchQuery.value,
                    perPage: perPage.value,
                    page: currentPage.value,
                    sortBy: sortBy.value,
                    sortDesc: isSortDirDesc.value
                }
			})
			.then(response => {
				const { total, exams } = response.data;
				callback(exams);
				totalExams.value = total;
			})
			.catch(() => {
				toast({
					component: ToastificationContent,
					props: {
						title: 'Error al obtener la lista de exámenes',
						icon: 'AlertTriangleIcon',
						variant: 'danger'
					}
				});
			})
	};

	return {
		fetchCourseExams,
		tableColumns,
		perPage,
		currentPage,
		totalExams,
		dataMeta,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refExamsListTable,

		refetchData
	}
}