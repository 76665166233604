import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useCourseInscriptionsList(courseId) {

    // Use toast
    const toast = useToast();

    const course = ref(courseId);
    const refInscriptionsListTable = ref(null);

    const styleColumns = {
        thClass: 'text-center',
        tdClass: 'text-center'
    }

    // Table Handlers
    const tableColumns = [
        { key: 'student', sortable: true, label: 'PERSONAL' },
        { key: 'document_number', sortable: true, label: 'NÚMERO DOCUMENTO' },
        { key: 'phone', sortable: true, label: 'TELÉFONO CELULAR' },
        { key: 'user_email', sortable: true, label: 'CORREO ELECTRÓNICO' },
        { key: 'contracting_company_name', sortable: true, label: 'EMPRESA CONTRATISTA', ...styleColumns },
        { key: 'amount', sortable: true, label: 'MONTO PAGADO', ...styleColumns },
        { key: 'createdAt', sortable: true, label: 'FECHA DE INSCRIPCIÓN', ...styleColumns }
    ];

    const perPage = ref(10);
    const totalInscriptions = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('id');
    const isSortDirDesc = ref(false);

    const contractingCompaniesFilter = ref(null);

    const dataMeta = computed(() => {
        const localItemsCount = refInscriptionsListTable.value ? refInscriptionsListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalInscriptions.value,
        }
    });

    const refetchData = () => {
        refInscriptionsListTable.value.refresh()
    };

    watch([currentPage, perPage, searchQuery, contractingCompaniesFilter], () => {
        refetchData()
    });

    const fetchCourseInscriptions = (ctx, callback) => {
        store
            .dispatch('app-course/fetchInscriptions', {
                id: course.value,
                queryParams: {
                    q: searchQuery.value,
                    perPage: perPage.value,
                    page: currentPage.value,
                    sortBy: sortBy.value,
                    sortDesc: isSortDirDesc.value,

                    contractingcompanies: contractingCompaniesFilter.value
                }
            })
            .then(response => {
                const { total, inscriptions } = response.data;
                callback(inscriptions);
                totalInscriptions.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener la lista de inscritos.',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                });
            })
    };

    return {
        fetchCourseInscriptions,
        tableColumns,
        perPage,
        currentPage,
        totalInscriptions,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refInscriptionsListTable,

        refetchData,

        // EXTRA FILTERS
        contractingCompaniesFilter
    }
}
