<template>

	<div>

		<!-- Table Container Card -->
		<b-card
			no-body
			class="mb-0">

			<div class="m-2">

				<!-- Table Top -->
				<b-row>

					<!-- Per Page -->
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>registros</label>
					</b-col>

				</b-row>

			</div>

			<b-table
				ref="refExamsListTable"
				class="position-relative table-exams"
				:style="'height: inherit'"
				:items="fetchCourseExams"
				responsive
				:fields="tableColumns"
				primary-key="id2"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No se encontraron registros coincidentes"
				:sort-desc.sync="isSortDirDesc"
			>

				<template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle mb-1"></b-spinner>
                        <div><b>CARGANDO</b></div>
                    </div>
                </template>

				<template #head(time)="data">
					<span v-html="data.label"></span>
				</template>

				<template #head(total_score)="data">
					<span v-html="data.label"></span>
				</template>

				<template #head(attempts)="data">
					<span v-html="data.label"></span>
				</template>

				<template #head(questions_count)="data">
					<span v-html="data.label"></span>
				</template>

				<template #head(qty_random_questions)="data">
					<span v-html="data.label"></span>
				</template>

				<template #cell(title)="data">
					<span class="font-weight-bold d-block text-nowrap">
						{{ data.item.title }}
					</span>
				</template>

				<template #cell(time)="data">
					<span class="d-block text-nowrap">
						{{ data.item.time }}
					</span>
				</template>

				<template #cell(total_score)="data">
					<span class="d-block text-nowrap">
						{{ data.item.total_score }}
					</span>
				</template>

				<template #cell(attempts)>
					<span class="d-block text-nowrap">2</span>
				</template>

				<template #cell(is_public)="data">
					<span class="d-block text-nowrap">
						<b-badge
							pill
							:variant="`light-${data.item.is_public ? 'success' : 'danger'}`"
						>
							{{ data.item.is_public ? 'PUBLICADO' : 'NO PUBLICADO' }}
						</b-badge>
                    </span>
				</template>

				<template #cell(createdAt)="data">
					<span class="d-block text-nowrap">						
						{{ moment(data.item.createdAt).format('DD/MM/YYYY HH:mm:ss') }}
					</span>
				</template>
				
				<template #cell(actions)="data">
					<b-dropdown
						variant="link"
						no-caret
						:right="$store.state.appConfig.isRTL">

						<template #button-content>
							<feather-icon
								icon="MoreVerticalIcon"
								size="16"
								class="align-middle text-body"
							/>
						</template>

						<b-dropdown-item :to="{ name: 'exams-edit', params: { slug: data.item.slug } }">
							<feather-icon icon="EditIcon" />
							<span class="align-middle ml-50">Editar</span>
						</b-dropdown-item>

						<b-dropdown-item
                            v-if="data.item.is_public"
                            @click="hideExam(data.item.id)"
                        >
                            <feather-icon icon="EyeOffIcon" />
                            <span class="align-middle ml-50">Ocultar</span>
                        </b-dropdown-item>

                        <b-dropdown-item
							v-if="!data.item.is_public"
                            @click="showExam(data.item.id)"
                        >
                            <feather-icon icon="EyeIcon" />
                            <span class="align-middle ml-50">Publicar</span>
                        </b-dropdown-item>
					</b-dropdown>
				</template>

			</b-table>

			<div class="mx-2 mb-2">
				<b-row>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start">
						<span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
					</b-col>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end">

						<b-pagination
							v-model="currentPage"
							:total-rows="totalExams"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item">

							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>

					</b-col>

				</b-row>
			</div>
		</b-card>
		
	</div>

</template>

<script>

	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
	import { useToast } from 'vue-toastification/composition';
	import useCourseExamsList from './useCourseExamsList';
	import { onUnmounted } from '@vue/composition-api';
	import examStoreModule from './examStoreModule';
	import VueSweetalert2 from 'vue-sweetalert2';
	import vSelect from 'vue-select';
	import store from '@/store';
	import moment from 'moment';
	import Vue from 'vue';    
	import 'animate.css';
	
	Vue.use(VueSweetalert2);
	
	export default {
		components: {
			vSelect
		},
		props: {
            courseData: {
                type: Object,
                required: true
            }
        },
		data() {
            return {
                moment
            }
        },
		setup(props) {

			// USE TOAST
			const toast = useToast();

			// REFS
            const courseId = props.courseData.id;

			const EXAM_APP_STORE_MODULE_NAME = 'app-exam';

			// REGISTER MODULE
			if (!store.hasModule(EXAM_APP_STORE_MODULE_NAME)) store.registerModule(EXAM_APP_STORE_MODULE_NAME, examStoreModule);

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(EXAM_APP_STORE_MODULE_NAME)) store.unregisterModule(EXAM_APP_STORE_MODULE_NAME);
			});

			const hideExam = (id) => {
		
				Vue.swal({
					title: '¿Está seguro(a) de ocultar el examen?',
					text: '¡Si no lo está, puede cancelar la acción!',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Sí, ocultar!',
					cancelButtonText: 'Cancelar',
					customClass: {
						confirmButton: 'btn btn-primary',
						cancelButton: 'btn btn-outline-danger ml-1'
					},
					showClass: {
						popup: 'animate__animated animate__tada'
					},
					buttonsStyling: false
				}).then(result => {

					if (result.value) 
					{
						const payload = { publication: 0 };

						store.dispatch('app-exam/publicationExam', { id, payload })
							.then( response => {

								refetchData();

								toast({
									component: ToastificationContent,
									props: {
										title: response.data.message,
										icon: 'CheckIcon',
										variant: 'success'
									}
								});

							})
							.catch( () => {

								toast({
									component: ToastificationContent,
									props: {
										title: 'Error al ocultar el examen',
										icon: 'AlertTriangleIcon',
										variant: 'danger'
									}
								});

							});
					}

				});

			};

			const showExam = (id) => {

				Vue.swal({
					title: '¿Está seguro(a) de publicar el examen?',
					text: '¡Si no lo está, puede cancelar la acción!',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Sí, publicar!',
					cancelButtonText: 'Cancelar',
					customClass: {
						confirmButton: 'btn btn-primary',
						cancelButton: 'btn btn-outline-danger ml-1'
					},
					showClass: {
						popup: 'animate__animated animate__tada'
					},
					buttonsStyling: false
				}).then(result => {

					if (result.value) 
					{
						const payload = { publication: 1 };
						
						store.dispatch('app-exam/publicationExam', { id, payload })
							.then( response => {

								refetchData();

								toast({
									component: ToastificationContent,
									props: {
										title: response.data.message,
										icon: 'CheckIcon',
										variant: 'success'
									}
								});

							})
							.catch( () => {

								toast({
									component: ToastificationContent,
									props: {
										title: 'Error al publicar el examen',
										icon: 'AlertTriangleIcon',
										variant: 'danger'
									}
								});

							});
					}

				});

			};
			
			const {
				fetchCourseExams,
				tableColumns,
				perPage,
				currentPage,
				totalExams,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refExamsListTable,
				refetchData
			} = useCourseExamsList(courseId);
			
			return {
				fetchCourseExams,
				tableColumns,
				perPage,
				currentPage,
				totalExams,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refExamsListTable,
				refetchData,

				// ACTIONS
				hideExam,
				showExam
			}
		}
	}

</script>

<style lang="scss" scoped>

	.per-page-selector {
		width: 90px;
	}    

</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-select.scss';
	@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>